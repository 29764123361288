<template>
  <div class="ccc">
    <TopHead></TopHead>
    <logoNav></logoNav>
    <div class="uc_con">

      <div class="uc_title d_f ali_c flex_1">
        <div class="uc_green"></div>
        <div>子分销商查询</div>
      </div>

      <div class="t_l">
        <el-form :inline="true" :model="searchInfo" class="demo-form-inline">
          <el-form-item label="子分销商名称">
            <el-input placeholder="子分销商名称"></el-input>
          </el-form-item>
          <el-form-item label="审核状态">
            <el-select placeholder="审核状态">
              <el-option label="区域一" value="shanghai"></el-option>
              <el-option label="区域二" value="beijing"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button @click="searchInfo = {}">重置</el-button>
            <el-button type="primary" @click="search">查询</el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>
    <div class="uc_con">

      <div class="uc_title d_f ali_c flex_1">
        <div class="uc_green"></div>
        <div>子分销商列表</div>
        <div class="flex_1">
          <!--李思慧在蓝湖上标注了添加子分销商功能暂时不做-->
          <!--<div class="add_btn" @click="add">添加子分销商</div>-->
        </div>
        <div class="d_f ali_c">
          <div class="right_btn cur_p">
            <img src="../assets/images/sd_suoding.png" alt="">
            <span>锁定</span>
          </div>
          <div class="right_btn cur_p">
            <img src="../assets/images/sd_jihuo.png" alt="">
            <span>激活</span>
          </div>

        </div>
      </div>

      <el-table
        ref="multipleTable"
        :data="list"
        stripe
        :header-cell-style="{'text-align':'center','background-color': '#F4F4F4','font-size': '14px','color': '#333','font-weight': 'bold'}"
        tooltip-effect="dark"
        style="width: 100%"
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" width="55" align="center"> </el-table-column>
        <el-table-column label="子分销商名称" align="center">
          <template #default="scope">{{ scope.row.date }}</template>
        </el-table-column>
        <el-table-column prop="name" label="登录名称" align="center"> </el-table-column>
        <el-table-column prop="address" label="联系人" align="center" show-overflow-tooltip></el-table-column>
        <el-table-column prop="address" label="联系人手机号" align="center" show-overflow-tooltip></el-table-column>
        <el-table-column prop="address" label="审核状态" align="center" show-overflow-tooltip></el-table-column>
        <el-table-column prop="address" label="审核意见" align="center" show-overflow-tooltip></el-table-column>
        <el-table-column prop="address" label="操作" align="center" show-overflow-tooltip>

          <template #default="scope">
            <div class="d_i">
              <div class="right_btn cur_p" @click="changeRow(scope.row)">
                <img src="../assets/images/sd_xiugai.png" alt="">
                <span>修改</span>
              </div>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
  export default {
    name: "subordinate",
    data() {
      return {
        searchInfo:{},
        list:[{date:1},{date:2}],
      }
    },
    methods: {
      //点击查询
      search(){


      },
      //点击添加子分销商
      add(){


      },
      //点击table修改
      changeRow(row){
        console.log(row)

      },
      handleSelectionChange(){

      },
    }
  }
</script>

<style scoped lang="scss">
  .el-table__header-wrapper{
    background-color: #F4F4F4;
  }
  .el-table{
    border: 1px solid #F4F4F4;
    text-align: center;
  }
  .right_btn{
    margin-left: 22px;
  }
  :v-deep .el-form-item__label{
    font-size: 18px;
  }
</style>
